export function setSNSWindow() {
    const baseDeviceWidth = getComputedStyle(document.documentElement).getPropertyValue("--baseDeviceWidth"); //cssから取得
    const trigger = [...document.querySelectorAll(".js-snswindow_trigger")];
    const windows = [...document.querySelectorAll(".l-snswindow")];

    trigger.forEach((el) => {
        el.addEventListener("click", () => {
            const target = el.getAttribute("data-window");

            //初期化処理
            windows.forEach((el) => el.classList.remove("-active"));

            trigger.forEach((el) => {
                el.getAttribute("data-window") !== target && el.classList.remove("-active");
            });

            const targetWindow = document.querySelector(`.l-snswindow[data-window=${target}]`);

            if (!targetWindow) {
                return;
            }

            if (el.classList.contains("-active")) {
                targetWindow.classList.remove("-active");
                el.classList.remove("-active");
            } else {
                targetWindow.classList.add("-active");
                el.classList.add("-active");
            }

            const rect = el.getBoundingClientRect();

            //absPositionにするかどうか
            if (el.getAttribute("data-absmode") || (el.getAttribute("data-nrwabsmode") && window.innerWidth < baseDeviceWidth)) {
                targetWindow.style.position = "absolute";
                rect.y += window.scrollY;
            } else {
                targetWindow.style.position = "fixed";
            }

            //offsetの有無
            if (el.getAttribute("data-nrwoffsetx") && window.innerWidth < baseDeviceWidth) {
                rect.x += parseInt(el.getAttribute("data-nrwoffsetx"));
            }

            targetWindow.style.top = `${rect.y}px`;
            targetWindow.style.left = `${rect.x}px`;
        });
    });
}
