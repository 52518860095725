export function gnav() {
    const open = document.querySelector(".js-gnav_open");
    const close = document.querySelector(".js-gnav_close");
    const translatebtn = document.querySelector(".l-header__translate");

    open.addEventListener("click", () => {
        document.body.classList.add("-gnav_active");
    });
    close.addEventListener("click", () => {
        document.body.classList.remove("-gnav_active");
    });

    translatebtn.addEventListener("click", () => {
        document.querySelector(".l-header__translate_window").classList.toggle("-active");
    });
}
