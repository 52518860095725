import Modal from "./modal";
import ScrollFunc from "./scrollfunc";
import CommonFunction from "./common_functions";
export default class UI {
    constructor() {
        this.screenHeight = 0;
        this.modal = new Modal();
        this.scrollfunc = new ScrollFunc();
        this.CommonFunction = new CommonFunction(this.scrollfunc);

        this.init();
    }

    init() {
        //methods
        this.update();
        this.eventBind();
        this.modal.eventBind(); //topでもmodalインスタンスを使うのでメソッドを実行しない限りeventbindしないようにする
    }

    update() {
        this.screenHeight = window.innerHeight;
        document.documentElement.style.setProperty("--screenHeight", `${this.screenHeight}px`);
    }

    eventBind() {
        window.addEventListener("resize", this.update);
    }
}
