export default class Util {
    constructor() {
        this.init();
    }

    init() {
        this.setUAClass();
    }

    setUAClass() {
        const isIE = window.MSInputMethodContext && document.documentMode;

        if (isIE) {
            document.body.classList.add("-ie");
        }
    }
}
