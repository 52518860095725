export function consoleAA() {
    //ロゴのコンソール
    console.log(
        "    ..gMMMMMMMNa+...         ...Jf  ,                       .+,           JJJJJ.             .JJJJJJ\n" +
            " .dMMMMMMMMMMMMMMMMMMMMMMMMMMMM#^ .g]                    .uMMMMN,         MMMMM{             -MMMMMM\n" +
            '        .MMMMMMMMMMMMMMMMMM#"`  .MM]                 ..MMMMMMMMMN,       MMMMM{             -MMMMMM\n' +
            "        dMMMMM[   _??77?!    ..gMMMM]               .&M#` TMMMMMMMMN,     MMMMM{             -MMMMMM\n" +
            "       JMMMMMMN,            dMMMMMMM]             .gMM#     TMMMMMMMMp    MMMMM{             -MMMMMM\n" +
            "       MMMMMMMMN,           dMMMMMMM]            .MMMM:      (MMMMMMMMp   MMMMM{             -MMMMMM\n" +
            "     jgMMMMMMMMMMNggggggg.  dMMMMMMM]           .MMMMM        ,MMMMMMMM,  MMMMM{             -MMMMMM\n" +
            "      ,MMMMMMMMMMMMMMMMMM_  dMMMMMMM]     .[    JMMMMM.        ,MMMMMMMN  MMMMM{             -MMMMMM\n" +
            "        ,MMMMMMMMMMR        dMMMMMMM]    .M]   .MMMMMM]         JMMMMMMM  MMMMM{    .Ma      -MMMMMM\n" +
            "         ,MMMMMMMMMMb       dMMMMMMM]   .MMb   ,MMMMMMN.         MMMMMMM  MMMMM{  .MMMMN,    -MMMMMM\n" +
            "          (MMMMMMMMMN       dMMMMMMM]   MMMM,  .MMMMMMMN         dMMMMMF  MMMMM{ (MMMMMMMe   -MMMMMM\n" +
            "           dMMMMMMMMM       dMMMMMMM]  .MMMMN,  dMMMMMMMh        dMMMMM!  MMMMMaMMMMMMMMMMN, -MMMMB`\n" +
            "  .g.       MMMMMMMM#       dMMMMMMM]   HMMMMMe .MMMMMMMMN,      MMMMM^   MMMMMMMMMMMMMMMMMMp-MMM^\n" +
            " .MMMx     .MMMMMMMM       dMMMMMMM]   .MMMMMMN..WMMMMMMMMe    .MMM#!    MMMMMMMMMMMMMMMMMMMMMD\n" +
            '(MMMMMMa...MMMMMMMM^        dMMMMMMM]     TMMMMMN. ?MMMMMMMMNe..MM#^      MMMMMMMMM" TMMMMMMM@`\n' +
            ' 7MMMMMMMMMMMMMMM"          dMMMMMMM]....+MMMMMMMb   ?MMMMMMMMMM"`        MMMMMMMD    .WMMMM^\n' +
            '    ?"MMMMMMM#"            dMMMMMMMMMMMMMMMMMMMMN      7WMMM9           MMMMM#       /M"\n\n\n©AMUSE INC., All Rights Reserved.'
    );
}
