export function bgSlideShow() {
    let index = 0;
    const delay = 4000;
    const wrapper = document.querySelector(".js-bgslide");

    //要素がない場合は終了
    if (!wrapper) {
        return;
    }

    const items = [...wrapper.querySelectorAll(".js-bgslide__item")];
    const randomIndex = Math.floor(Math.random() * items.length);
    index = randomIndex;

    const loop = () => {
        items.forEach((el) => el.classList.remove("-active"));
        items[index].classList.add("-active");

        index++;

        if (index > items.length - 1) {
            index = 0;
        }
        setTimeout(loop, delay);
    };

    loop();
}
