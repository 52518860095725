import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default class ScrollFunc {
    constructor() {
        this.baseDeviceWidth = getComputedStyle(document.documentElement).getPropertyValue("--baseDeviceWidth"); //cssから取得
        this.init();
    }

    init() {
        this.smoothScroll();
        window.addEventListener("load", this.onScroll);
        window.addEventListener("load", this.onFooterScroll);
    }

    refresh() {
        ScrollTrigger.refresh();
    }

    onScroll() {
        const revealElm = document.querySelectorAll(".js-scrollreveal");

        [...revealElm].forEach((el) => {
            ScrollTrigger.create({
                trigger: el,
                start: "top 80%",
                //markers: true,
                onEnter: () => {
                    el.classList.add("-reveal");
                },
            });
        });
    }

    onFooterScroll() {
        if (window.innerWidth < this.baseDeviceWidth) {
            return;
        }

        //フッターまで到達したときのfunction
        const footer = document.querySelector(".l-footer");
        const sidesns = document.querySelector(".l-sidesns");
        const snswindow = [...document.querySelectorAll(".l-snswindow")];

        ScrollTrigger.create({
            trigger: footer,
            start: "top 100%",
            //markers: true,
            onEnter: () => {
                sidesns.classList.add("-disable");
                snswindow.forEach((el) => el.classList.remove("-active"));
            },
            onLeaveBack: () => {
                sidesns.classList.remove("-disable");
            },
        });
    }

    smoothScroll() {
        const triggers = document.querySelectorAll(".js-smoothscroll");
        const pagetop = document.querySelectorAll(".js-pagetop");

        triggers.forEach((el) => {
            el.addEventListener("click", (e) => {
                e.preventDefault();
                const hash = el.getAttribute("href");
                const offset = 100;
                gsap.to(window, { duration: 1, scrollTo: { y: hash, offsetY: offset }, ease: "circ.inOut" });
            });
        });

        pagetop.forEach((el) => {
            el.addEventListener("click", (e) => {
                e.preventDefault();
                gsap.to(window, { duration: 1, scrollTo: { y: 0 }, ease: "circ.inOut" });
            });
        });
    }
}
