import { bgSlideShow } from "./functions/bgslideshow";
import { setSNSWindow } from "./functions/setsnswindow";
import { accordion } from "./functions/accordion";
import { gnav } from "./functions/gnav";
import { consoleAA } from "./functions/console_aa";
import { morecont } from "./functions/morecont";
import { setIframe } from "./functions/set_iframe.es6";

export default class CommonFunction {
    constructor(scrollfunc) {
        this.init(scrollfunc);
    }

    init(scrollfunc) {
        bgSlideShow();
        setSNSWindow();
        accordion(scrollfunc);
        gnav();
        consoleAA();
        morecont(scrollfunc);
        setIframe();
    }
}
