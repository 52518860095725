export function morecont(scrollFunc) {
    const trigger = [...document.querySelectorAll(".js-more_trigger")];

    trigger.forEach((el) => {
        el.addEventListener("click", () => {
            const cont = el.nextElementSibling;

            el.classList.toggle("-active");
            cont.classList.toggle("-active");

            setTimeout(() => {
                scrollFunc.refresh();
            }, 500);
        });
    });
}
